.loading-wrapper {    
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 3;    
  }
  
  .loading-content {
    /* margin: 0px 10px 0px 10px;     */    
    padding: 20px;
    background-color: white;
    border-radius: 6px;    
    display: flex;
    align-items: center;    
    justify-content: center;
    flex-direction: column;
  }

  .loading-content-error {
    width: 80%;
    padding: 20px;
    background-color: white;
    border-radius: 6px;    
    display: flex;
    align-items: center;    
    justify-content: center;
    flex-direction: column;
  }

  .loading-content-text-title {
    font-weight: bold;
  }
  
  .loading-content-text {   
    padding-top: 5px; 
    font-size: 18px;    
    text-align: center;        
  }

  .loading-content-text span {    
  }

  .loading-content-button {
    padding-top: 10px;
  }
  
  .loading-content-button button {
    border-radius: 4px;
    padding: 5px 10px 5px 10px;
    background-color: #ffc900;
    font-size: 12pt;
    border: #ffdd21;    
  }

  @media (min-width: 501px) {
    .loading-wrapper {
      width: 500px;
    }
  }
  