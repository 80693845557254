
.button-yellow-container span {
    border: none;
    display: inline-block;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    width:100%;
    /* height: 55px; */
    min-height: 55px;
    background-color: #ebebeb;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    padding: 15px;
}
.disabled-button-yellow-container span {
    background-color: #ffea74;
    opacity: 0.7;
}
.button-yellow-container-floating button {
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    bottom: 10px;
    position: fixed;
    width:92.5%;
    height: 55px;
    background-color: #ffc900;
    z-index: 2;
}

.disabled-button-yellow-container-floating button {
    background-color: #ffea74;
    opacity: 0.7;
}

@media only screen and (min-width: 500px) {
    .button-yellow-container-floating button{
    width:470px;
    }
}
@media only screen and (max-width: 320px) {
    .button-yellow-container-floating button{
        width:90%;
    }
}
@media only screen and (max-width: 375px) {
    .button-yellow-container-floating button{
        width:92%;
    }
}
