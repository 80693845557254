.card-store-picture {
    border: 1px;
    border-radius: 20px;
    height: 100%;
    width: 100%;
}

.card-store-picture .row {
    margin: 5%;
}

.card-foto {
    height: 185.97px;
    width: 100%;
    border-radius: 20px;
    background-color: #DFDFDF;
    border: 0px;
    /* margin: auto; */
    padding-top: 50px;
    padding-right: 65px;
    padding-left: 65px;
}
.card-foto-tablets {
    height: 212px;
    width: 212px;
    border-radius: 20px;
    background-color: #DFDFDF;
    border: 0px;
    margin: auto;
    padding-top: 55px;
    padding-left: 55px;
}

.desktop-card-foto {
    height: 196px;
    width: 196px;
    border-radius: 20px;
    background-color: #DFDFDF;
    border: 0px;
    margin: auto;
    padding-top: 50px;
    padding-right: 10px;
}


@media screen and (max-width: 320px) {
    .card-foto img {
        display: block;
        margin-left: 40px;
        margin-right: auto;
    }
}

@media screen and (min-width: 321px) {
    .card-foto img {
        display: block;
        margin-left: auto;
        margin-right: auto;

    }
    .desktop-card-foto img {
        display: block;
        margin-left: auto;
        margin-right: auto;

    }

}


.card-store-picture .row button {
    border-radius: 8px;
    border: 3px solid black;
    margin-top: 5%;
    margin-bottom: 2%;
    width: 100%;
    height: 100%;
}

.card-store-picture .row .button-store-right {
    border: transparent;
    background-color: #FFDD21;
}

.card-store-picture .row .button-store-left {
    background-color: white;
}

.store-picture-text {
    margin: 5%;
}

.register-title {
    font-size: 24px;
    line-height: 32px;
}

.register-step {
    font-size: 16px;
    line-height: 24px;
    color: #888888;
}

.input-error-register-phone:active {
    border-color: #E31E24;
    height: 55;
    width: 408;
    margin-right: 50;
}

.text-error-register {
    font-size: 13px;
    line-height: 16px;
    color: #E31E24;
    margin: 0px 0px 0px 10px;
}

.input-style-register {
    line-height: 32px;
}
.input-style-register-mobile {
    line-height: 24px;
}
.size-font-20 {
    font-size: 20px;
}
.size-font-16 {
    font-size: 16px;
}

.register-text-picture, .register-text-picture a{
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #888888;
}

.register-done-title-desktop,
.register-done-title-tablets, 
.register-text-picture, 
.register-text-picture a, 
.text-error-register, 
.input-style-register,
.input-style-register-mobile,
.register-step,
.register-title  {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
}

.register-done-title-desktop {
    font-size: 40px;
    line-height: 56px;
}
.register-done-title-tablets {
    font-size: 24px;
    line-height: 32px;
}
.register-done-desktop {
    font-size: 20px;
    line-height: 32px;
}
.register-done-tablets {
    font-size: 16px;
    line-height: 24px;
}

.register-done-color {
    color: #262626;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    padding: 5px !important;
}
