/* body.modal-open> :not(.modal) {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
} */

.card-language-choosed {
    color: #262626;
    font-weight: 700;
    font-size: 16px;
    /* background-color: #FFBC21; */

}

.card-country-selector-1-selected {
    color: #262626;
    font-weight: 700;
    font-size: 20px;
}

.card-language,
.card-country-selector-1 {
    color: #000000;
    cursor: pointer;
    font-weight: 400;
    /* background-color: #eeeeee; */
}

.card-country-selector-1 {
    font-size: 20px;
}

.card-language {
    font-size: 16px;
}

.card-language:hover,
.card-country-selector-1:hover {
    font-weight: 700;
}

.card-language:active,
.card-country-selector-1:active {
    font-weight: 700;
}

/* .card-language:hover, */
/* .card-country-selector-1:hover {
    color: #FFFFFF;
    background-color: #FFBC21;
    cursor: pointer;
} */


.card-country-selector-1,
.card-country-selector-1-selected {
    margin: 3px;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 20px;
    font-size: 20px;
    height: 45px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
}


.card-language,
.card-language-choosed {
    margin-bottom: 20px;
    font-size: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 10px;
}

/* 
.modal-height {
    height: 300px;
} */

.backdrop-modal-initial {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    top: 0;
    left: 0;
}

.backdrop-modal-initial-close {
    /* height: 100%;
    width: 100%;
    position: fixed;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    top: 0;
    left: 0; */
    display: none;
}

.modal-initial-container {
    display: grid;
    place-items: center;
}

.transition-modal-initial-open {
    z-index: 3;
    height: 70%;
    width: inherit;
    position: fixed;
    bottom: 0;
    transition: height 400ms ease-in-out 0s;
}

.transition-modal-initial-mobile-open {
    z-index: 3;
    height: 100%;
    width: inherit;
    position: fixed;
    bottom: 0;
    transition: height 400ms ease-in-out 0s;
}

.transition-modal-initial-close {
    z-index: 3;
    height: 0%;
    width: inherit;
    position: fixed;
    bottom: 0;
    transition: height 400ms ease-in-out 0s;
}

.transition-modal-initial-city-open {
    z-index: 3;
    height: 90%;
    width: inherit;
    position: fixed;
    bottom: 0;
    transition: height 400ms ease-in-out 0s;
}

.transition-modal-initial-city-mobile-open {
    z-index: 3;
    height: 100%;
    width: 100vw;
    position: fixed;
    bottom: 0;
    transition: height 400ms ease-in-out 0s;
    background-color: white;
    display: flex;
    justify-content: center;
}

.transition-modal-initial-city-mobile-close {
    z-index: 3;
    height: 0%;
    width: inherit;
    position: fixed;
    bottom: 0;
    transition: height 400ms ease-in-out 0s;
    background-color: white;
}

.transition-modal-initial-city-close {
    z-index: 3;
    height: 0%;
    width: inherit;
    position: fixed;
    bottom: 0;
    transition: height 400ms ease-in-out 0s;
}

.transition-dropdown {
    border-radius: 8px;
    background-color: white;
    float: left;
    position: absolute;
    overflow-y: scroll;
    width: 248px;
    transition: height 400ms ease-in-out 0s;
    margin-top: 8px;
}

.language-open {
    border: 1px solid black;
    height: 392px;

}

.language-close {
    border: 0px solid black;
    height: 0;
}

.btn-chooser-language-container {
    width: 240px;
    height: 64px;
    border: 1px solid black;
    display: grid;
    place-items: center;
    border-radius: 8px;
    cursor: pointer;
}

.container-content-modal-initial-city {
    background-color: white;
    max-height: 90%;
    /* height: 90%; */
    width: 738px;
    padding-top: 48px;
    padding-left: 48px;
    padding-right: 48px;
    display: flex;
    flex-direction: column;
    /* grid-template-rows: 33.3% 33.3% 33.3%; */
}

.search-input {
    width: 100%;
    height: 56px;
    font-size: 16px;
    border: 2px solid;
    border-color: #F5F5F5;
    border-radius: 10px;
    padding-left: 52px;
}

.search-input-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-input-container {
    position: relative;
    display: flex;
    width: 100%;
    flex-grow: 2;
}

.search-input-container-mobile {
    position: relative;
    display: flex;
    width: 100%;
    /* flex-grow: 2; */
}

.search-input-button {
    position: absolute;
    margin-left: 50px;
}

.modal-initial-body {
    background-color: white;
    height: 392px;
    width: 672px;
    padding-top: 48px;
    padding-left: 48px;
}

.btn-chooser-language-container-flex {
    display: flex;
    flex-direction: row;
}

.list-language-drop-down {
    display: flex;
    flex-direction: column;
}

.list-country-chooser {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 20px;
}

.container-content-modal-initial-city-chooser {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
}

.list-city-chooser {
    display: grid;
    grid-template-columns: 30.3% 30.3% 30.3%;
    flex-grow: 4;
    overflow-y: scroll;
    padding-bottom: 20px;
    padding-top: 24px;
}

.margin-top20 {
    margin-top: 20px;
}

.margin-top25 {
    margin-top: 25px;
}
.city-card {
    margin-top: 25px;
    cursor: pointer;
}

.text-city-chooser {
    font-size: 24px;
    font-weight: 400;
}

@media only screen and (max-width: 1235px) {
    .container-content-modal-initial-city {
        width: 682px;
    }
}

@media only screen and (max-width: 991px) {

    .card-country-selector-1,
    .card-country-selector-1-selected {
        height: auto;
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

@media only screen and (max-width: 743px) {
    .modal-initial-body {
        background-color: white;
        min-height: 100vh;
        min-width: 100vw;
    }

    .container-content-modal-initial-city {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 272px;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .container-content-modal-initial-city-chooser-mobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px
    }

    .list-city-chooser-mobile {
        display: grid;
        grid-template-columns: 100%;
        overflow-y: scroll;
        padding-bottom: 20;
    }
}