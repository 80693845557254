.container-variation {
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.container-variation-item {
    display: flex;
    flex-direction: column;
}

.content-body-variation {
    /* background-color: antiquewhite; */
    border-radius: 6px;
    background: #FFFFFF;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.08);
    padding: 16px 8px 16px 8px;
    margin: 10px 0px 10px 0px;
    cursor: pointer;
}

.content-body-variation-product {
    background-color: antiquewhite;
    border-radius: 6px;
    background: #FFFFFF;    
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.08);
    padding: 16px 8px 16px 8px;
    /* padding: 0px 10px 0px 10px; */
    /* margin: 5px 0px 5px 0px; */
    cursor: pointer;
}

.content-bottom-variation {
    width: 500px;
    /* background-color: #FFC900; */
    position: fixed;
    bottom: 0;
}

.item-variation {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.item-variation span {
    font-size: 16px;
}

.item-variation-input {
    flex: 1;
    display: flex;
    justify-content: center;
    align-self: center;
}

.item-variation-text {
    flex: 8;
}

.item-variation-text-title {
    flex: 8;
    display: flex;
    padding: 5px 8px 5px 8px;
}

.item-variation-text-title {    
    font-weight: bold;    
}

.content-body-variation .item-variation-desc {
    font-size: 14px;
    color: #969696;
}

.content-bottom-variation-button {
    display: flex;
    flex: 1;
    justify-content: center;
    margin-bottom: 10px;
}

.content-button-variation-submit {
    width: 230px;
    padding: 5px;
    background-color: #FFC900;
    border: 0px;
    border-radius: 6px;
}

.content-button-variation-cancel {
    width: 230px;
    padding: 5px;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #FFC900;
}

.button-variation-width-90-percent{
    width: 90%;
}

@media (min-width: 0px) and (max-width: 499px) {
    .content-bottom-variation {
        width: 100%;
    }

    .content-button-variation-submit {
        width: 45%;
        padding: 5px;
        background-color: #FFC900;
        border: 0px;
        border-radius: 6px;
    }

    .content-button-variation-cancel {
        width: 45%;
        padding: 5px;
        background-color: white;
        border-radius: 6px;
        border: 1px solid #FFC900;
    }
    .button-variation-width-90-percent{
        width: 90%;
    }
}