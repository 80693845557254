/* global call */

/* navbar */
.navbar .me-auto {
    align-items: flex-end;
}

.navbar-gray {
    background-color: #EEEEEE;
}

.navbar-gray .ms-auto a {
    color: #888888;
    text-decoration: none;
}

.navbar-gray .translation {
    color: rgb(70, 69, 69);
}

.navbar-gray .text-navbar-gray a {
    color: #888888;
    text-decoration: none;
}

.fake-dropdown a {
    color: #000000;
    text-decoration: none;
}

.fake-dropdown a:hover {
    color: #000000;
    text-decoration: underline;
}

a .instagram:hover {
    background-color: #000000;
    border-radius: 8px;
}

/* end of navbar  */



.background-pink {
    background-color: #FFECE6;
    background-size: cover;
    position: inherit;
}

.card-white {
    background-color: white;
    background-size: cover;
    position: inherit;
}

.container-default-navbar,
.container-default {
    margin-left: auto;
    margin-right: auto;
}

.col-4 .card .text-center {
    border-radius: 16px;
}

.card .inside {
    border-radius: 8px;
}

.card-contact-1 {
    position: relative;
}

.card-1 .btn-warning-up,
.card-4 .btn-warning-up,
.card-7 .btn-warning-up {
    padding: 16px 48px;
    background-color: #FFDD21;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.card-1 .btn-warning-up:hover,
.card-4 .btn-warning-up:hover,
.card-7 .btn-warning-up:hover {
    border-color: #F3BC09;
    background-color: #F3BC09;
    flex: none;
    order: 1;
    flex-grow: 0;
}

a .btn-regisnow,
.container-default .btn-regisnow {
    padding: 16px 48px;
    border-color: #000000;
    color: black;
    flex: none;
    order: 1;
    flex-grow: 0;
    border-radius: 8px;
    margin-right: -25px;
}

a .btn-regisnow:hover,
.container-default .btn-regisnow:hover {
    border-color: #F3BC09;
    background-color: #F3BC09;
}

/* qna */
.accordion-button:focus {
    box-shadow: none !important;
    border-color: rgba(0, 0, 0, .125) !important;
}

.accordion .accordion-button:not(.collapsed) {
    color: #000000;
    background-color: white;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

/* end of qna */

.footer {
    padding-top: 10px;
}

.footer p,
.footer a,
.navbar-fixed-bottom a,
.navbar-fixed-bottom p,
.navbar-fixed-bottom text {
    color: #000000;
    font-size: 13px;
    line-height: 1.6;
}

.padding-vertical25 {
    padding: 25px 0px 50px 0px;
}

.padding-vertical70 {
    padding: 70px 0px;
}

.btn-registernow-yellow {
    background-color: #FFDD21;
}

@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@media (min-width:1236px) {
    .fake-dropdown {
        z-index: 3;
        padding: 30px 0px;
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s; 
    }

    /* navbar */
    .navbar-expand-lg .navbar-collapse {
        margin-left: -25px;
        /* margin-right: -25px;     */
    }

    .navbar-gray a:hover {
        text-decoration: underline;
    }

    .navbar-light .navbar-nav .nav-link {
        margin-left: -5px;
    }

    /* end of navbar */

    .container-default {
        width: 1200px;
        height: 120%;
        padding-top: 56px;
        padding-bottom: 56px;
    }

    .container-default-navbar,
    .navbar-fixed-bottom {
        width: 1200px;
    }

    .label {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 56px;
        color: #262626;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    p {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #262626;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    b {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: #262626;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    .right-side {
        height: 576px;
        width: 576px;
        border-radius: 16px;
    }

    /* card 1 */
    .card-1 .container-default .left-side {
        margin-top: 30%;
    }

    .card-1 .left-side a {
        margin-top: 28px;
    }

    /* card 3 */
    .left-side ul {
        margin-left: -20px;
    }

    .card-3 .left-side h1 {
        font-size: 40px;
        margin-bottom: 5%;
    }

    .card-3 .left-side .downloads {
        margin-top: 5%;
    }

    /* card 4 */
    .card-4 .text .text-second {
        margin-top: 5%;
    }

    .card-4 .btn-warning {
        margin-top: 48px;
    }

    /* card 5 */
    .right-side-5 {
        margin-top: 15%;
    }

    /* card 6 */
    .card-6 .text-center {
        margin: 5%;
        height: 230px;
    }

    .card-6 .text-center p {
        text-decoration: none;
    }

    .card-6 a {
        text-decoration: none;
    }

    .card-6 a:hover {
        text-decoration: underline black;
    }

    /* end of home page  */

    .card-7 {
        margin-bottom: 120px;
    }

    .card-8 {
        position: relative;
    }

    .card-8 .component-center img {
        margin: 2px;
        width: auto;
        height: auto;
    }

    .store-location {
        margin-top: 2%;
        width: 100%;
        height: 100vh auto;
        margin-bottom: 5%;
    }

    .card .text {
        align-items: center;
    }

    /* style guides */
    .frequenly-asked {
        height: auto;
    }

    .frequenly-asked .row {
        margin-top: 5%;
        margin-bottom: 5%;
    }


    /* style contact us */
    .card-contact-1 {
        background-color: white;
        border: none;
    }

    .container iframe {
        width: 100%;
        height: 400px;
    }

    .card .inside {
        margin-top: 5%;
        width: 368px;
        height: 352px;
        border-radius: 8px;
    }

    .card .inside p {
        margin-top: 3%;
    }

    /* style adding product */
    .adding-product .row img {
        width: auto;
        height: auto;
    }

    /* style accept order */
    .container .accept-order .row {
        margin: auto;
        width: 50%;
    }

    .container-default iframe {
        width: 100%;
        height: 472px;
    }

    .container-default .inside {
        position: relative;
    }

    .card .row .accordion {
        border: 1px;
        font-size: 20pt;
    }

    .navbar-fixed-bottom p {
        display: flex;
        margin-top: 15px;
    }

}

@media (min-width:744px) and (max-width:1235px) {    
    .fake-dropdown {
        display: block;
        position: fixed;
        z-index: 3;
        left: 0;
        top: 0;
        background-color: aliceblue;
        width: 100%;
        margin-top: 0px;
        min-height: 100vh;
        max-height: 100%;
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s;              
    }

    .fake-dropdown-contact {
        padding: 20px 0px;
    }

    .fake-dropdown-content {
        padding: 40px 0px;
    }

    .fake-dropdown-content-title {
        font-weight: bold;
    }

    .background-pink {
        background-color: #F5ECE6;
        background-size: cover;
    }

    .background-pink-absolute {
        background-color: #FFECE6;
        background-size: cover;
        overflow-y: scroll;
    }

    .card-white {
        background-color: white;
        background-size: cover;
        position: inherit;
    }

    .container-default-navbar {
        margin-left: auto;
        margin-right: auto;
    }

    .container-default {
        margin-left: auto;
        margin-right: auto;
    }

    .container-default {
        width: 680px;
        height: 100%;
        padding-top: 32px;
        padding-bottom: 0px;
    }


    /* navbar */
    .navbar-collapse {
        margin-left: -20px;
    }

    .nav-link .logo-maxim {
        margin-left: -35px;
    }

    .navbar-light .navbar-toggler {
        color: rgba(0, 0, 0, .55);
        border-color: rgba(0, 0, 0, .1);
        margin-right: -20px;
    }

    .navbar-light .navbar-toggler {
        color: rgba(0, 0, 0, .55);
        border-color: rgba(0, 0, 0, .1);
        margin-right: -20px;
    }

    .ms-auto .btn {
        margin-right: -25px;
    }

    /* end of navbar */

    .container-default-navbar,
    .navbar-fixed-bottom {
        width: 680px;
    }

    a .btn-regisnow,
    .container-default .btn-regisnow {
        padding: 16px 16px;
        border-color: #000000;
        color: black;
        flex: none;
        order: 1;
        flex-grow: 0;
        border-radius: 8px;
        width: 100%;
    }


    .label {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #262626;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    b {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    p {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #262626;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    .card-1 .btn-warning-up,
    .card-4 .btn-warning-up,
    .card-5 .btn-warning-up,
    .card-7 .btn-warning-up {
        padding: 16px 48px;
        background-color: #FFDD21;
        border-color: none;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    /* card 1 */
    .card-1 .container-default .left-side {
        margin-top: 15%;
    }

    .card-1 .left-side a {
        margin-top: 28px;
    }

    .right-side,
    .right-side img {
        height: 328px;
        width: 328px;
        border-radius: 16px;
    }

    /* card-2 */
    .card-text-rows-2 .col-1 {
        margin-bottom: 80px;
    }


    /* card-3 */
    .left-side ul {
        margin-left: -20px;
    }

    .left-side img {
        width: 149.78px;
        left: 0.73%;
        right: 0.73%;
        top: 2.19%;
        bottom: 2.18%;
    }

    /* card-4 */
    .card-4 .col-1,
    .card-4 .col-2 {
        margin-bottom: 70px;
    }

    .card-4 .btn-warning {
        margin-top: 48px;
    }

    /* card-5 */

    /* style contact us */
    .card .inside {
        margin-top: 5%;
        width: 300px;
        height: 250px;
    }

    /* style adding product */
    .adding-product .row img {
        width: 400px;
        height: auto;
    }

    .adding-product .row ul {
        margin: 10%;
    }

    /*  */

    /* card 6 */
    .card-6 .text-center {
        height: 240px;
        width: 240px;
        border-radius: 16px;
    }

    .card-6 .text-center p {
        text-decoration: none;
    }

    .card-6 a {
        text-decoration: none;
    }

    .card-6 a:hover {
        text-decoration: underline black;
    }

    .navbar-fixed-bottom p {
        display: flex;
        margin-top: 20px;
    }

    .card-7 {
        margin-bottom: 60px;
    }

    /* style contact us */
    .card-contact-1 {
        background-color: white;
        border: none;
    }

    .container-default iframe {
        width: 100%;
        height: 267.47px;
    }

    .card .inside {
        margin-top: 5%;
        width: 328px;
        height: 280px;
    }

    .card .inside p {
        margin-top: 3%;
    }

    a .btn-regisnow,
    .container-default .btn-regisnow {
        padding: 16px 16px;
        border-color: #000000;
        color: black;
        flex: none;
        order: 1;
        flex-grow: 0;
        border-radius: 8px;
    }

    /* registration */
    .tutorial .img-center img {
        height: 480px;
        width: 328px;

    }

    /* end of registration */

}

@media (min-width:0px) and (max-width:743px) {        
    .fake-dropdown {
        display: block;
        position: fixed;
        z-index: 3;
        left: 0;
        top: 0;        
        width: 100%;
        margin-top: 0px;
        min-height: 100vh;
        max-height: 100%;
        overflow-y: scroll;
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s;                             
    }

    .fake-dropdown-contact {
        padding: 20px 0px;
    }

    .fake-dropdown-content {
        padding: 40px 0px;
    }

    .fake-dropdown-content-title {
        font-weight: bold;
    }

    .background-pink {
        background-color: #F5ECE6;
        background-size: cover;
    }

    .background-pink-absolute {
        background-color: #FFECE6;
        background-size: cover;
    }

    .card-white {
        background-color: white;
        background-size: cover;
        position: inherit;
    }

    .container-default-navbar,
    .container-default {
        margin-left: auto;
        margin-right: auto;
    }

    .container-default {
        width: 272px;
        height: 120%;
        padding-top: 24px;
        padding-bottom: 0px;
    }

    .container-default-navbar {
        width: 320px;
    }

    .nav-link .logo-maxim {
        margin-left: -15px;
    }

    .card-1 .btn-warning-up,
    .card-4 .btn-warning-up,
    .card-7 .btn-warning -up {
        padding: 16px 48p-upx;
        background-color: #FFDD21;
        flex: none;
        order: 1;
        flex-grow: 0;
        width: 100%;
    }

    a .btn-regisnow,
    .container-default .btn-regisnow {
        padding: 16px 16px;
        border-color: #000000;
        color: black;
        flex: none;
        order: 1;
        flex-grow: 0;
        border-radius: 8px;
        width: 100%;
    }

    .label {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #262626;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    b {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    .label {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #262626;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    p,
    text {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #262626;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    /* card 1 */
    .container-default .left-side {
        margin-top: 15px;
    }

    .right-side,
    .right-side img {
        height: 272px;
        width: 272px;
        border-radius: 16px;
    }

    .left-side {
        width: 272px;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    /* card 2 */
    .card-column-2,
    .card-column-4 {
        margin: 35px 0px 35px 0px;
    }

    .left-side ul {
        margin-left: -20px;
    }

    .left-side-3 img {
        width: 149.78px;
        left: 0.73%;
        right: 0.73%;
        top: 2.19%;
        bottom: 2.18%;
    }



    /* style adding product */
    .adding-product .row img {
        width: 400px;
        height: auto;
    }

    .adding-product .row ul {
        margin-left: 10%;
    }

    /* style accept order */
    .container .accept-order .row iframe {
        height: 500px;
    }

    /* card 5 */
    .right-side-5 {
        width: 272px;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .right-side-5 p {
        margin-top: 15px;
    }


    /* card 6 */
    .card-6 .text-center {
        height: 240px;
        width: 240px;
        border-radius: 16px;
    }

    .card-6 .text-center p {
        text-decoration: none;
    }


    .card-6 a {
        text-decoration: none;
    }

    .card-6 a:hover {
        text-decoration: underline black;
    }

    .card-7 {
        margin-bottom: 30px;
    }

    /* style contact us */
    .card-contact-1 {
        background-color: white;
        border: none;
    }

    .container-default iframe {
        width: 100%;
        height: 267.47px;
    }

    .card .inside {
        margin-top: 5%;
        width: 272px;
        height: 267px;
    }

    .card .inside p {
        margin-top: 3%;
    }

    /* registration */
    .tutorial .img-center img {
        height: 400px;
        width: 272px;
    }

    /* end of registration */
}