.transition-dropdown-navbar {
    border-radius: 8px;
    background-color: white;
    float: right;
    top: 0;
    right: calc(20vw - 100px);
    position: absolute;
    overflow-y: scroll;
    width: 248px;
    transition: height 400ms ease-in-out 0s;
    margin-top: 8px;
    z-index: 3;
}

.transition-dropdown-navbar-home {
    border-radius: 8px;
    background-color: white;
    /* float: right;
    top: 0;
    right: calc(20vw - 100px); */
    position: absolute;
    overflow-y: scroll;
    width: 248px;
    transition: height 400ms ease-in-out 0s;
    margin-top: 8px;
    z-index: 3;
}

.language-open {
    border: 1px solid black;
    height: 392px;

}

.language-close {
    border: 0px solid black;
    height: 0;
}

.card-language-choosed-navbar {
    color: #262626;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    /* background-color: #FFBC21; */

}

.card-language-navbar {
    color: #000000;
    cursor: pointer;
    font-weight: 400;
    /* background-color: #eeeeee; */
}

.card-language-navbar {
    font-size: 16px;
}

.card-language-navbar:hover {
    font-weight: 400;
    background-color: #FFDD21;
}

.card-language-navbar,
.card-language-choosed-navbar {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 16px;
    font-size: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 4px;
}

.card-language-choosed-navbar-home-mobile,
.card-language-choosed-navbar-home {
    color: #262626;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    /* background-color: #FFBC21; */

}

.card-language-navbar-home,
.card-language-navbar-home-mobile {
    color: #000000;
    cursor: pointer;
    font-weight: 400;
    /* background-color: #eeeeee; */
}

.card-language-navbar-home,
.card-language-navbar-home-mobile {
    font-size: 16px;
}

.card-language-navbar-home:hover {
    font-weight: 400;
    background-color: #FFDD21;
}

.card-language-navbar-home,
.card-language-choosed-navbar-home {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 16px;
    font-size: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 4px;
}

.card-language-navbar-home-mobile,
.card-language-choosed-navbar-home-mobile {
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    margin-bottom: 16px;
    font-size: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    /* padding-left: 10px; */
    border-radius: 4px;
}

.mobile-initial-modal-open {
    background-color: salmon;
}

.mobile-initial-modal-close {
    display: none;
}

.modal-initial-container-content {
    height: 100%;
    width: 272px;
    display: flex;
    flex-direction: column;
}

.modal-initial-container-content-language {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.modal-list-language {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}