.container-product {
    background-color: #cfcfcf;
}

.container-product-content {
    background-color: #fafafa;
    min-height: 100vh;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.363) 0px 2px 8px 0px;;
}

.container-form-product {
   padding: 21px 16px 21px;
}

.container-image-loading {
    margin: auto;
    width: 50%;
    display: flex;
    justify-content: center;
}

.container-pick-image {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.contain-margin-bottom {
    margin-bottom: 100px;
}

.cursor-pointer {
    cursor: pointer;
}

.image-default-container {
    border: 1px;
    border-radius: 20px;
    height: 100%;
    width: 100%;
    padding-right: 65px;
    padding-left: 65px;
}

.input-product {
    height: 100%;
    width: 100%;
    border-radius: 60px;
    color: #ebebeb;
    transition: width 400ms linear 0s;
}
.input-product-hid {
    height: 0%;
    width: 0%;
    border-radius: 60px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #ebebeb;
    transition: width 400ms linear 0s;
    display: none;
}
.container-text-label-switch-product {
    display: flex;
    height: 48px;
    justify-content: space-between;
    margin-top: 11px;
}
.container-label-switch-product {
    margin-top: auto;
    margin-bottom: auto;
}
.text-error-image {
    text-align: center;
    color: #e31e24;
    font-size: 12px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}
.label-switch-product {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    font-weight: 400px;
}


@media only screen and (min-width: 500px) {
    .container-product-content{
        max-width: 499px;
        width: 100%;
        margin: auto;
    }
    .button-yellow-container button{
    width:470px;
    }
}